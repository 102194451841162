import gql from "graphql-tag";

export const exchangeMobileAppTokenMutation = gql`
    mutation exchangeMobileAppToken($token: String!, $userAgent: String) {
        exchangeMobileAppToken(token: $token, user_agent: $userAgent) {
            jwt
            jwt_expires_on
            refresh_token
            user_uuid
        }
    }
`;
