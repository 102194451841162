<template lang="pug">
    span Загрузка...
</template>
<script>
import { exchangeMobileAppTokenMutation } from "@/graphql/redirectPage.js";

export default {
    data() {
        return {
            personId: this.$route.query.person ?? "",
            authToken: this.$route.query.token ?? "",
        };
    },
    async mounted() {
        if (this.personId !== "" && this.authToken !== "") {
            await this.$apollo
                .mutate({
                    mutation: exchangeMobileAppTokenMutation,
                    variables: {
                        token: this.authToken,
                        userAgent: navigator.userAgent,
                    },
                })
                .then((response) => {
                    const responseData = response.data.exchangeMobileAppToken;
                    this.$store.dispatch("auth/successAuth", {
                        jwt: responseData.jwt,
                        refresh_token: responseData.refresh_token,
                        user_id: responseData.user_uuid,
                    });
                    this.$router.push(
                        this.$router.resolve({
                            name: "person",
                            params: { uuid: this.personId },
                        }).href,
                    );
                })
                .catch(() => {
                    this.$router.push("/");
                });
        } else {
            this.$router.push({ name: "error-404" });
        }
    },
};
</script>
